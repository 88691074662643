<template>
    <b-row>

        <b-col
                cols="12"
                md="6"
                class=" mb-1"
        >

            <b-table-simple class="table-details" responsive no-border-collapse>
                <b-tbody>
                    <b-tr>
                        <b-td width="40%">{{$t('label_first_name')}}</b-td>
                        <b-td class="actions">

                            {{itemData.first_name}}

                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td width="40%">{{$t('label_last_name')}}</b-td>
                        <b-td class="actions">
                            {{itemData.last_name}}
                        </b-td>
                    </b-tr>
                  <b-tr>
                        <b-td width="40%">{{$t('label_company_name')}}</b-td>
                        <b-td class="actions">
                            {{itemData.firm_name}}
                        </b-td>
                    </b-tr>
                  <b-tr>
                        <b-td width="40%">{{$t('label_nip')}}</b-td>
                        <b-td class="actions">
                            {{itemData.nip}}
                        </b-td>
                    </b-tr>
                  <b-tr>
                        <b-td width="40%">{{$t('label_swift_number')}}</b-td>
                        <b-td class="actions">
                            {{itemData.swift_number}}
                        </b-td>
                    </b-tr>
                  <b-tr>
                        <b-td width="40%">{{$t('label_pesel_number')}}</b-td>
                        <b-td class="actions">
                            {{itemData.pesel}}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td width="40%">{{$t('label_email_address')}}</b-td>
                        <b-td class="actions">

                            {{itemData.email}}

                        </b-td>
                    </b-tr>
                  <b-tr>
                        <b-td width="40%">{{$t('label_private_email')}}</b-td>
                        <b-td class="actions">

                            {{itemData.email_private}}

                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td width="40%">{{$t('label_phone')}}</b-td>
                        <b-td class="actions">

                            {{itemData.phone}}

                        </b-td>
                    </b-tr>
                  <b-tr>
                        <b-td width="40%">{{$t('label_private_phone')}}</b-td>
                        <b-td class="actions">

                            {{itemData.phone_private}}

                        </b-td>
                    </b-tr>

                </b-tbody>
            </b-table-simple>
        </b-col>
        <b-col
                cols="12"
                md="6"
                class=" mb-1"
        >
            <b-table-simple class="table-details" responsive no-border-collapse>
                <b-tbody>
                  <b-tr>
                    <b-td>{{$t('label_status')}}</b-td>
                    <b-td>
                      {{$t(itemData.status? itemData.status.translation_index : '')}}

                    </b-td>
                  </b-tr>

                  <b-tr>
                    <b-td>{{$t('label_origin')}}</b-td>
                    <b-td>
                      {{$t(itemData.referral_user_id ? $t('label_marketing') : '')}}
                    </b-td>
                  </b-tr>
                    <b-tr>
                        <b-td>{{$t('label_address_from_agreement')}}</b-td>
                        <b-td>
                            {{itemData.address_agreement}}
                        </b-td>
                    </b-tr>
                  <b-tr>
                        <b-td>{{$t('label_correspondence_address')}}</b-td>
                        <b-td>
                            {{itemData.address_correspondence}}
                        </b-td>
                    </b-tr>
                  <b-tr>
                        <b-td>{{$t('label_deal_type')}}</b-td>
                        <b-td>
                            {{itemData.deal_type? itemData.deal_type.name : ''}}
                        </b-td>
                    </b-tr>
                    <b-tr v-if="$can('view', 'deal.tab_details') && itemData.deals">
                        <b-td>{{$t('label_related_deal')}}</b-td>
                        <b-td>

                            <template v-for="relDeal,ind in itemData.deals">
                                <router-link

                                        :key="'deal_'+relDeal.id"

                                        :to="{ name: 'deal-details', params: { id: relDeal.id } }"
                                >
                                    {{ relDeal.number}}
                                </router-link>
                                {{((ind+1 == itemData.deals.length)? '' : ' | ')}}
                            </template>


                        </b-td>
                    </b-tr>

                    <b-tr>
                        <b-td>{{$t('label_consultant_leading_case')}}</b-td>
                        <b-td>

                            {{itemData.consultant? itemData.consultant.name : ''}}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{$t('label_recommended')}}</b-td>
                        <b-td>

                            {{itemData.agent? itemData.agent.name : ''}}
                        </b-td>
                    </b-tr>
                    <b-tr>
                        <b-td>{{$t('label_related_users')}}</b-td>
                        <b-td>

                            <template v-for="user,ind in itemData.users">
                                <router-link
                                        :to="resolveRouteToRedirectUser(user, true)"
                                        :key="user.id"
                                >
                                    {{user.name}}
                                </router-link>
                                {{((ind+1 == itemData.users.length)? '' : ' | ')}}
                            </template>


                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </b-col>
    </b-row>
</template>

<script>
    import {
        BRow, BCol,
        BTableSimple, BTbody, BTr, BTd, BButton, BBreadcrumbItem,

    } from 'bootstrap-vue'
    // import { CONTACT_PREFIX as PREFIX} from './../moduleHelper'
    import {resolveRouteToRedirectUser} from './../../pagesHelper'

    export default {
        components: {
            BRow, BCol,
            BTableSimple, BTbody, BTr, BTd, BButton, BBreadcrumbItem,
        },
        props: ['itemData'],
        data() {
            return {
                resolveRouteToRedirectUser
            }
        },

    }
</script>